import MainButton from "./MainButton";

const ButtonPrimary = ({ className = "", ...args }) => {
  return (
    <MainButton
      className={`ttnc-ButtonPrimary bg-primary-6000 text-neutral-50 hover:bg-primary-700 disabled:bg-opacity-70 ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
