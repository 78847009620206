import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";

var Navigation = () => {
  return (
    <ul
      className="nc-Navigation relative hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1"
      role="menu"
    >
      {NAVIGATION_DEMO.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
};

export default Navigation;
