import React from "react";
import ButtonClose from "../button/ButtonClose";
import Logo from "../Logo";
import { Disclosure } from "@headlessui/react";
import { NAVIGATION_DEMO } from "data/navigation";
import SocialsList from "../socials-list/SocialsList";
import { VscChevronDown } from "react-icons/vsc";
import SwitchDarkMode from "../SwitchDarkMode";
import Link from "next/link";

const NavMobile = ({ data = NAVIGATION_DEMO, onClickClose }) => {
  const _renderMenuChild = (item) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <Link
              href={{ pathname: i.href || undefined }}
              activeClassName="text-secondary"
              className="mt-[2px] flex rounded-lg px-4 py-2.5 text-sm font-medium text-neutral-900 hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-800"
            >
              <span
                className={!i.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex flex-grow justify-end"
                  >
                    <VscChevronDown
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </Link>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item) => {
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <Link
          href={{ pathname: item.href || undefined }}
          activeClassName="text-secondary"
          className="flex w-full items-center rounded-lg py-2.5 px-4 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100 dark:hover:bg-neutral-800"
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={onClickClose}
          >
            {item.name}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex flex-grow justify-end"
              >
                <VscChevronDown
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </Link>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="h-screen w-full max-w-sm transform divide-y-2 divide-neutral-100 overflow-y-auto bg-white py-2 shadow-lg ring-1 transition dark:divide-neutral-800 dark:bg-neutral-900 dark:ring-neutral-700">
      <div className="py-6 px-5">
        <Logo />
        <div className="mt-5 flex flex-col text-sm text-neutral-700 dark:text-neutral-300">
          <span>
            Breed assortiment vakantiehuizen binnen en buiten Europa. Van
            kastelen tot grotwoningen en van villa&apos;s tot chalets.
          </span>

          <div className="mt-4 flex items-center justify-between">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col space-y-1 py-6 px-2">
        {data.map(_renderItem)}
      </ul>
    </div>
  );
};

export default NavMobile;
