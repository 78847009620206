import React from "react";
import Image from "next/image";

const Avatar = ({
  imgUrl,
  userName,
  hasChecked = false,
  sizeClass = "h-6 w-6 text-sm",
}) => {
  const url = imgUrl || "";
  const name = userName || "";

  return (
    <div
      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full ring-1 ring-white dark:ring-neutral-900 ${sizeClass}`}
      style={{ backgroundColor: url ? undefined : "#4F46E5" }}
    >
      {url && (
        <Image
          className="absolute inset-0 w-full h-full object-cover rounded-full"
          src={url}
          alt={name}
          width={36}
          height={36}
        />
      )}
      <span className="wil-avatar__name">{name[0]}</span>
      {hasChecked && (
        <span
          className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute w-4 h-4 -top-0.5 -right-0.5`}
        >
          <i className="las la-check" />
        </span>
      )}
    </div>
  );
};

export default Avatar;
