import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import {
  HiOutlineUserCircle,
  HiOutlineHeart,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineSupport,
} from "react-icons/hi";
import Avatar from "../shared/Avatar";

const solutions = [
  { name: "Account", href: "/account", icon: HiOutlineUserCircle },
  { name: "Favorieten", href: "/account/favorieten", icon: HiOutlineHeart },
  { name: "Boekingen", href: "/account/boekingen", icon: HiOutlineHome },
];

const AvatarDropdown = ({ user }) => {
  const router = useRouter();

  return (
    <div className="AvatarDropdown">
      <Popover className="relative z-50">
        {() => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar
                sizeClass="w-8 h-8 sm:w-9 sm:h-9"
                imgUrl={user?.image}
                userName={user?.name}
                hasChecked={false}
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-3 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutions.map((item, index) => (
                      <Link
                        href={item.href}
                        key={index}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <hr className="border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    <span
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => router.push("/veelgestelde-vragen")}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <HiOutlineSupport
                          aria-hidden="true"
                          className="w-6 h-6"
                        />
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium">Support</p>
                      </div>
                    </span>

                    <span
                      onClick={() =>
                        signOut({
                          callbackUrl: `${window.location.origin}/inloggen`,
                        })
                      }
                    >
                      <a
                        href="#"
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <HiOutlineLogout
                            aria-hidden="true"
                            className="w-6 h-6"
                          />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium">Uitloggen</p>
                        </div>
                      </a>
                    </span>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default AvatarDropdown;
