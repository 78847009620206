export const NAVIGATION_DEMO = [
  {
    id: "Home",
    href: "/",
    name: "Home",
  },
  {
    id: "Vakantiehuizen",
    href: "/vakantiehuizen",
    name: "Vakantiehuizen",
  },
  {
    id: "Top 5 landen",
    href: "/vakantiehuizen",
    name: "Bestemmingen",
    type: "dropdown",
    children: [
      { id: "Nederland", href: "/bestemmingen/nederland", name: "Nederland" },
      { id: "België", href: "/bestemmingen/belgie", name: "België" },
      { id: "Frankrijk", href: "/bestemmingen/frankrijk", name: "Frankrijk" },
      { id: "Spanje", href: "/bestemmingen/spanje", name: "Spanje" },
      {
        id: "Oostenrijk",
        href: "/bestemmingen/oostenrijk",
        name: "Oostenrijk",
      },
    ],
  },
  {
    id: "Last minute",
    href: "/last-minute",
    name: "Last minute",
  },
];
