import React, { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { Loader } from "@mantine/core";
import Logo from "../shared/Logo";
import Navigation from "./Navigation";
import SwitchDarkMode from "../shared/SwitchDarkMode";
// import SearchDropdown from "../shared/SearchDropdown";
import ButtonPrimary from "../shared/button/ButtonPrimary";
import MenuBar from "../shared/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import { VscAccount } from "react-icons/vsc";
import { useRouter } from "next/router";

export const Header = () => {
  const router = useRouter();
  const { data: session, status } = useSession();
  const [isTop, setisTop] = useState(true);

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function scrollFunction() {
    const $head = document.getElementById("topvakantiehuizen-header");
    if (!$head) {
      return;
    }
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      !!isTop && setisTop(false);
    } else {
      setisTop(true);
    }
  }

  return (
    <div
      id="topvakantiehuizen-header"
      className="nc-Header z-40 w-full lg:sticky lg:top-0 lg:left-0 lg:right-0"
    >
      <div
        className={`nc-MainNav1 relative z-50 ${
          isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
      >
        <div className="container relative flex items-center justify-between space-x-4 py-5 xl:space-x-8">
          <div className="flex flex-grow items-center justify-start space-x-4 sm:space-x-10 2xl:space-x-14">
            <Logo />
            <Navigation />
          </div>
          <div className="flex flex-shrink-0 items-center justify-end space-x-1 text-neutral-700 dark:text-neutral-100">
            <div className="hidden items-center space-x-1 lg:flex">
              <SwitchDarkMode />
              {/*<SearchDropdown />*/}
              <div className="px-1" />
              {status === "loading" ? (
                <Loader variant="dots" color="gray" />
              ) : (
                <>
                  {session ? (
                    <AvatarDropdown user={session?.user} />
                  ) : (
                    <ButtonPrimary onClick={() => router.push("/inloggen")}>
                      Aanmelden
                    </ButtonPrimary>
                  )}
                </>
              )}
            </div>
            <div className="flex items-center lg:hidden">
              <SwitchDarkMode />
              {status === "loading" ? (
                <Loader variant="dots" color="gray" />
              ) : (
                <>
                  {session ? (
                    <AvatarDropdown user={session?.user} />
                  ) : (
                    <button
                      onClick={() => router.push("/inloggen")}
                      className={`flex h-12 w-12 items-center justify-center rounded-full text-2xl text-neutral-700 hover:bg-neutral-100 focus:outline-none dark:text-neutral-300 dark:hover:bg-neutral-800 md:text-3xl flex items-center lg:hidden`}
                    >
                      <span className="sr-only">Mijn account</span>
                      <VscAccount className="h-7 w-7" aria-hidden="true" />
                    </button>
                  )}
                </>
              )}
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
