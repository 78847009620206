import React from "react";
import { BsFacebook } from "react-icons/bs";

const SocialsList = ({ className = "", itemClass = "block" }) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      <a
        className={itemClass}
        href="https://www.facebook.com/topvakantiehuizen"
        target="_blank"
        rel="noopener noreferrer"
        title={"Facebook TopVakantiehuizen.eu"}
      >
        <BsFacebook />
      </a>
    </nav>
  );
};

export default SocialsList;
