import Image from "next/image";
import Link from "next/link";
import Logo from "../shared/Logo";
import SocialsList1 from "../shared/socials-list/SocialsList";
import iDEAL from "../../public/assets/img/betaalmethoden/iDEAL.png";
import PayPal from "../../public/assets/img/betaalmethoden/paypal.png";
import MisterCash from "../../public/assets/img/betaalmethoden/bancontact-mister-cash.png";
import Mastercard from "../../public/assets/img/betaalmethoden/Mastercard.png";
import ReisCadeau from "../../public/assets/img/betaalmethoden/ReisCadeau.png";

const widgetMenus = [
  {
    id: "5",
    title: "Vakantie buitenland",
    menus: [
      { href: "/vakantiehuizen-in-duitsland", label: "Vakantie Duitsland" },
      { href: "/vakantiehuizen-in-frankrijk", label: "Vakantie Frankrijk" },
      { href: "/vakantiehuizen-in-spanje", label: "Vakantie Spanje" },
      { href: "/vakantiehuizen-in-oostenrijk", label: "Vakantie Oostenrijk" },
      { href: "/vakantiehuizen-in-belgie", label: "Vakantie België" },
    ],
  },
  {
    id: "1",
    title: "Meest geboekt",
    menus: [
      { href: "/wintersport-chalet", label: "Wintersport chalets" },
      {
        href: "/vakantie-in-een-skigebied",
        label: "Vakantie in een skigebied",
      },
      {
        href: "/vakantiehuis-op-een-vakantiepark",
        label: "Op een vakantiepark",
      },
      { href: "/vakantiehuis-met-zwembad", label: "Vakantiehuis met zwembad" },
      { href: "/luxe-vakantievillas", label: "Luxe vakantievilla's" },
    ],
  },
  {
    id: "2",
    title: "Vakantie in eigen land",
    menus: [
      {
        href: "/vakantiehuis-in-nederland",
        label: "Vakantiehuis in Nederland",
      },
      { href: "/appartement-in-nederland", label: "Appartement in Nederland" },
      { href: "/boerderij-in-nederland", label: "Boerderij in Nederland" },
      { href: "/villa-in-nederland", label: "Villa in Nederland" },
      {
        href: "/vakantiepark-in-nederland",
        label: "Vakantiepark in Nederland",
      },
    ],
  },
  {
    id: "4",
    title: "Informatie",
    menus: [
      // { href: "/vakantiehuizen", label: "Over TopVakantiehuizen" },
      { href: "/veelgestelde-vragen", label: "Veelgestelde vragen" },
      { href: "/disclaimer", label: "Disclaimer" },
      { href: "/privacy-verklaring", label: "Privacy verklaring" },
      { href: "/algemene-voorwaarden", label: "Algemene voorwaarden" },
    ],
  },
];

const Footer = () => {
  const renderWidgetMenuItem = (menu, index) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                className="text-neutral-6000 hover:text-black dark:text-neutral-300 dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative border-t border-neutral-200 py-24 dark:border-neutral-700 lg:py-32">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="col-span-2 grid grid-cols-4 gap-5 md:col-span-4 lg:flex lg:flex-col lg:md:col-span-1">
          <div className="col-span-2 md:col-span-1">
            <Logo footer={true} />
          </div>
          <div className="footer-social-icons col-span-2 flex items-center md:col-span-3">
            <SocialsList1
              footer={true}
              className="flex items-center space-x-3 lg:flex-col lg:items-start lg:space-x-0 lg:space-y-2.5"
            />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="container flex flex-row items-center justify-center mt-10 text-sm text-neutral-6000 dark:text-neutral-300">
        Veilig online betalen met
      </div>
      <div className="container flex flex-row items-center justify-center text-sm text-neutral-6000 dark:text-neutral-300">
        <div className="w-20">
          <Image
            width={56}
            height={56}
            src={iDEAL}
            alt="Betalen met iDEAL"
            title="Betalen met iDEAL"
            placeholder="blur"
          />
        </div>
        <div className="w-20">
          <Image
            width={56}
            height={56}
            src={Mastercard}
            alt="Betalen met creditcard"
            title="Betalen met creditcard"
            placeholder="blur"
          />
        </div>
        <div className="w-20">
          <Image
            width={70}
            height={70}
            src={MisterCash}
            alt="Betalen met Bancontact Mister Cash"
            title="Betalen met Bancontact Mister Cash"
            placeholder="blur"
          />
        </div>
        <div className="w-20">
          <Image
            width={56}
            height={56}
            src={ReisCadeau}
            alt="Betalen met Reis Cadeau"
            title="Betalen met Reis Cadeau"
            placeholder="blur"
          />
        </div>
        <div className="w-20">
          <Image
            width={100}
            height={100}
            src={PayPal}
            alt="Betalen met PayPal"
            title="Betalen met PayPal"
            placeholder="blur"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
