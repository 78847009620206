import React from "react";
import Link from "next/link";

const Logo = ({ footer = false }) => {
  return (
    <Link href="/" legacyBehavior>
      <a
        className={`ttnc-logo inline-block text-primary-6000 ${
          footer ? "footer-logo" : ""
        }`}
      >
        <h1
          className="custom-logo-styling select-none"
          style={{ color: footer ? "#111827" : "#009ce0" }}
        >
          TopVakantiehuizen.
        </h1>
      </a>
    </Link>
  );
};

export default Logo;
